import custom from "./markdown/custom";
import normal from "./markdown/normal";
import nenq from "./markdown/nenq";

import content from "./content.md";

import atomOneDark from "./code/atomOneDark";
import atomOneLight from "./code/atomOneLight";
import github from "./code/github";
import monokai from "./code/monokai";
import vs2015 from "./code/vs2015";
import xcode from "./code/xcode";

import macAtomOneDark from "./macCode/macAtomOneDark";
import macAtomOneLight from "./macCode/macAtomOneLight";
import macGithub from "./macCode/macGithub";
import macMonokai from "./macCode/macMonokai";
import macVs2015 from "./macCode/macVs2015";
import macXcode from "./macCode/macXcode";

import basic from "./basic";

export default {
  basic,
  normal,
  custom,
  nenq,
  code: {
    atomOneDark,
    atomOneLight,
    github,
    monokai,
    vs2015,
    xcode,
    macAtomOneDark,
    macAtomOneLight,
    macGithub,
    macMonokai,
    macVs2015,
    macXcode,
  },
  content,
};
